import { NavItem } from './nav-item.model';

export class ProjectDetailMinigridNav {
  public static item: NavItem[] = [
    {
      title: 'project-navigation.planning',
      url: 'planning',
      icon: '<img src="./assets/icons/line-icons/nav/grey/overview.svg" width="23px" height="23px">',
      testId: 'proj-nav-planning',
    },
    {
      title: 'project-navigation.project-application',
      url: 'project-application',
      icon: '<img src="./assets/icons/line-icons/nav/grey/application.svg" width="23px" height="23px">',
      testId: 'proj-nav-planning',
    },
    {
      title: 'project-navigation.files',
      url: 'files',
      icon: '<img src="./assets/icons/line-icons/nav/grey/files.svg" width="23px" height="23px">',
      testId: 'proj-nav-planning',
    },
    {
      title: 'project-navigation.sharing',
      url: 'sharing',
      icon: '<img src="./assets/icons/line-icons/nav/grey/sharing.svg" width="23px" height="23px">',
      testId: 'proj-nav-planning',
    },
    {
      title: 'project-navigation.milestones',
      url: 'milestones',
      icon: '<img src="./assets/icons/line-icons/project/grey/milestones.svg" width="23px" height="23px">',
      testId: 'proj-nav-milestones',
    },
    {
      title: 'project-navigation.connections',
      url: 'connections',
      icon: '<img src="./assets/icons/line-icons/project/grey/connections.svg" width="23px" height="23px">',
      testId: 'proj-nav-connections',
    },
    {
      title: 'project-navigation.messages',
      url: 'messages',
      icon: '<img src="./assets/icons/line-icons/nav/grey/messages.svg" width="23px" height="23px">',
      testId: 'proj-nav-message',
    },
  ];
}
